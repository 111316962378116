<template>
  <div>
  <div>
<p>SCHEDULE</p>
      <b-table-simple
          ref="schedule"

        responsive

      >

        <b-thead>
          <b-tr>
            <b-th></b-th>
            <b-th class="text-center">
              SUN
            </b-th>
            <b-th class="text-center">
              MON
            </b-th>

            <b-th class="text-center">
              TUE
            </b-th>

            <b-th class=" text-center">
              WED
            </b-th>

            <b-th class=" text-center">
              THU
            </b-th>

            <b-th class="text-center">
              FRI
            </b-th>

            <b-th class=" text-center">
              SAT
            </b-th>


          </b-tr>
        </b-thead>
        <b-tbody>

          <b-tr

          >
            <b-td>FROM</b-td>
            <b-td >

              <v-select

                  v-model="schedule.sun_from"
                  label="hour"
                  :reduce="val => val.hour"
        @input="changehour(1)"
                  :options="quendo"
              />

            </b-td>
            <b-td >

              <v-select

                  v-model="schedule.mon_from"
                  label="hour"
                  :reduce="val => val.hour"
                  @input="changehour(3)"
                  :options="quendo"
              />

            </b-td>
            <b-td >

              <v-select

                  v-model="schedule.tue_from"
                  label="hour"
                  @input="changehour(5)"
                  :reduce="val => val.hour"
                  :options="quendo"
              />

            </b-td>
            <b-td >

              <v-select

                  v-model="schedule.wed_from"
                  label="hour"
                  @input="changehour(7)"
                  :reduce="val => val.hour"
                  :options="quendo"
              />

            </b-td>
            <b-td >

              <v-select

                  v-model="schedule.thu_from"
                  label="hour"
                  :reduce="val => val.hour"
                  @input="changehour(9)"
                  :options="quendo"
              />

            </b-td>
            <b-td >

              <v-select

                  v-model="schedule.fri_from"
                  label="hour"
                  @input="changehour(11)"
                  :reduce="val => val.hour"
                  :options="quendo"
              />

            </b-td>
            <b-td >

              <v-select

                  v-model="schedule.sat_from"
                  label="hour"
                  @input="changehour(13)"
                  :reduce="val => val.hour"
                  :options="quendo"
              />

            </b-td>



          </b-tr>
          <b-tr

          >
            <b-td>TO</b-td>
            <b-td >

              <v-select

                  v-model="schedule.sun_to"
                  label="hour"
                  @input="changehour(2)"
                  :reduce="val => val.hour"
                  :options="quendo"
              />

            </b-td>
            <b-td >

              <v-select

                  v-model="schedule.mon_to"
                  label="hour"
                  @input="changehour(4)"
                  :reduce="val => val.hour"
                  :options="quendo"
              />

            </b-td>
            <b-td >

              <v-select

                  v-model="schedule.tue_to"
                  label="hour"
                  @input="changehour(6)"
                  :reduce="val => val.hour"
                  :options="quendo"
              />

            </b-td>
            <b-td >

              <v-select

                  v-model="schedule.wed_to"
                  label="hour"
                  @input="changehour(8)"
                  :reduce="val => val.hour"
                  :options="quendo"
              />

            </b-td>
            <b-td >

              <v-select

                  v-model="schedule.thu_to"
                  label="hour"
                  @input="changehour(10)"
                  :reduce="val => val.hour"
                  :options="quendo"
              />

            </b-td>
            <b-td >

              <v-select

                  v-model="schedule.fri_to"
                  label="hour"
                  @input="changehour(12)"
                  :reduce="val => val.hour"
                  :options="quendo"
              />

            </b-td>
            <b-td >

              <v-select

                  v-model="schedule.sat_to"
                  label="hour"
                  :reduce="val => val.hour"
                  @input="changehour(13)"
                  :options="quendo"
              />

            </b-td>


          </b-tr>
        </b-tbody>

      </b-table-simple>
    <p class="mt-1">HOUR WORKED
    </p>
    <div class="d-flex justify-content-start mb-2 " >
      <v-select
class="w-50"
        v-model="year"
        label="date"
     :reduce="val => val.date"
        @input="getDataHour"
        :options="dates"
    /></div>

<b-table-simple ref="hour_worked"   responsive>
    <b-thead>
      <b-tr>
        <b-th></b-th>
        <b-th >
          JAN
        </b-th>
        <b-th >
          FEB
        </b-th>

        <b-th >
          MAR
        </b-th>

        <b-th >
          APR
        </b-th>

        <b-th >
          MAY
        </b-th>

        <b-th >
          JUN
        </b-th>

        <b-th >
          JUL
        </b-th>
        <b-th >
          AGO
        </b-th>
        <b-th >
          SEP
        </b-th>
        <b-th >
          OCT
        </b-th>
        <b-th >
          NOV
        </b-th>
        <b-th >
          DEC
        </b-th>

      </b-tr>
    </b-thead>
  <b-tbody :key="cont">

    <b-tr

    >
      <b-td >DAY 5</b-td>
      <b-td  style="width: 8%;"  class="cursor-pointer" >
        <div>
          <span v-if="!onHour[1]"    @dblclick="openb(1)">{{hourWorked.jan_5 == null ? 0 : hourWorked.jan_5}}</span>
        </div>
        <b-form-input  type="number" v-if="onHour[1]"  v-model='hourWorked.jan_5'  @keyup.enter="saveHour(1,hourWorked.jan_5)"/>

      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span v-if="!onHour[3]"   @dblclick="openb(3)">{{hourWorked.feb_5 == null ? 0 : hourWorked.feb_5}}</span>
        <b-form-input type="number" v-if="onHour[3]" v-model="hourWorked.feb_5"   @keyup.enter="saveHour(3,hourWorked.feb_5)"/>
      </b-td>
      <b-td  style="width: 8%;" class="cursor-pointer">
        <span  v-if="!onHour[5]"  @dblclick="openb(5)">{{hourWorked.mar_5 == null ? 0 : hourWorked.mar_5}}</span>
        <b-form-input type="number" v-if="onHour[5]"  v-model="hourWorked.mar_5"   @keyup.enter="saveHour(5,hourWorked.mar_5)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span v-if="!onHour[7]"   @dblclick="openb(7)">{{hourWorked.apr_5 == null ? 0 : hourWorked.apr_5}}</span>
        <b-form-input type="number" v-if="onHour[7]"  v-model="hourWorked.apr_5"   @keyup.enter="saveHour(7,hourWorked.apr_5)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span  v-if="!onHour[9]"   @dblclick="openb(9)">{{hourWorked.may_5 == null ? 0 : hourWorked.may_5}}</span>
        <b-form-input type="number" v-if="onHour[9]"  v-model="hourWorked.may_5"   @keyup.enter="saveHour(9,hourWorked.may_5)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span v-if="!onHour[11]"    @dblclick="openb(11)">{{hourWorked.jun_5 == null ? 0 : hourWorked.jun_5}}</span>
        <b-form-input type="number" v-if="onHour[11]"  v-model="hourWorked.jun_5"  @keyup.enter="saveHour(11,hourWorked.jun_5)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span v-if="!onHour[13]"   @dblclick="openb(13)">{{hourWorked.jul_5 == null ? 0 : hourWorked.jul_5}}</span>
        <b-form-input type="number" v-if="onHour[13]"  v-model="hourWorked.jul_5"  @keyup.enter="saveHour(13,hourWorked.jul_5)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span  v-if="!onHour[15]"   @dblclick="openb(15)">{{hourWorked.aug_5 == null ? 0 : hourWorked.aug_5}}</span>
        <b-form-input type="number" v-if="onHour[15]"  v-model="hourWorked.aug_5"  @keyup.enter="saveHour(15,hourWorked.aug_5)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span v-if="!onHour[17]"    @dblclick="openb(17)">{{hourWorked.sep_5 == null ? 0 : hourWorked.sep_5}}</span>
        <b-form-input type="number"  v-if="onHour[17]"  v-model="hourWorked.sep_5"  @keyup.enter="saveHour(17,hourWorked.sep_5)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span v-if="!onHour[19]"    @dblclick="openb(19)">{{hourWorked.oct_5 == null ? 0 : hourWorked.oct_5}}</span>
        <b-form-input type="number" v-if="onHour[19]"  v-model="hourWorked.oct_5"  @keyup.enter="saveHour(19,hourWorked.oct_5)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span  v-if="!onHour[21]"   @dblclick="openb(21)">{{hourWorked.nov_5 == null ? 0 : hourWorked.nov_5}}</span>
        <b-form-input type="number" v-if="onHour[21]"  v-model="hourWorked.nov_5"  @keyup.enter="saveHour(21,hourWorked.nov_5)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span v-if="!onHour[23]"   @dblclick="openb(23)"> {{hourWorked.dec_5 == null ? 0 : hourWorked.dec_5}}</span>
        <b-form-input type="number" v-if="onHour[23]"  v-model="hourWorked.dec_5"  @keyup.enter="saveHour(23,hourWorked.dec_5)"/>
      </b-td>

    </b-tr>
    <b-tr>
      <b-td style="width: 8%;" class="cursor-pointer">DAY 20</b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span v-if="!onHour[2]"   @dblclick="openb(2)">{{hourWorked.jan_20 == null ? 0 :hourWorked.jan_20}}</span>
        <b-form-input type="number" v-if="onHour[2]" v-model="hourWorked.jan_20"   @keyup.enter="saveHour(2,hourWorked.jan_20)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span v-if="!onHour[4]"   @dblclick="openb(4)">{{hourWorked.feb_20 == null ? 0 :hourWorked.feb_20}}</span>
        <b-form-input type="number" v-if="onHour[4]"   v-model="hourWorked.feb_20"   @keyup.enter="saveHour(4,hourWorked.feb_20)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span v-if="!onHour[6]"   @dblclick="openb(6)">{{hourWorked.mar_20 == null ? 0 :hourWorked.mar_20}}</span>
        <b-form-input type="number" v-if="onHour[6]"   v-model="hourWorked.mar_20"   @keyup.enter="saveHour(6,hourWorked.mar_20)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span v-if="!onHour[8]"    @dblclick="openb(8)">{{hourWorked.apr_20 == null ? 0 :hourWorked.apr_20}}</span>
        <b-form-input type="number" v-if="onHour[8]"  v-model="hourWorked.apr_20"   @keyup.enter="saveHour(8,hourWorked.apr_20)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span v-if="!onHour[10]"    @dblclick="openb(10)">{{hourWorked.may_20 == null ? 0 :hourWorked.may_20}}</span>
        <b-form-input type="number" v-if="onHour[10]"   v-model="hourWorked.may_20"  @keyup.enter="saveHour(10,hourWorked.may_20)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span v-if="!onHour[12]"   @dblclick="openb(12)">{{hourWorked.jun_20 == null ? 0 :hourWorked.jun_20}}</span>
        <b-form-input type="number" v-if="onHour[12]"   v-model="hourWorked.jun_20"  @keyup.enter="saveHour(12,hourWorked.jun_20)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span v-if="!onHour[14]"   @dblclick="openb(14)">{{hourWorked.jul_20 == null ? 0 :hourWorked.jul_20}}</span>
        <b-form-input type="number" v-if="onHour[14]"   v-model="hourWorked.jul_20"  @keyup.enter="saveHour(14,hourWorked.jul_20)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span v-if="!onHour[16]"   @dblclick="openb(16)">{{hourWorked.aug_20 == null ? 0 :hourWorked.aug_20}}</span>
        <b-form-input type="number" v-if="onHour[16]"   v-model="hourWorked.aug_20"  @keyup.enter="saveHour(16,hourWorked.aug_20)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span v-if="!onHour[18]"   @dblclick="openb(18)">{{hourWorked.sep_20 == null ? 0 :hourWorked.sep_20}}</span>
        <b-form-input type="number" v-if="onHour[18]"   v-model="hourWorked.sep_20"  @keyup.enter="saveHour(18,hourWorked.sep_20)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span v-if="!onHour[20]"    @dblclick="openb(20)">{{hourWorked.oct_20 == null ? 0 :hourWorked.oct_20}}</span>
        <b-form-input type="number" v-if="onHour[20]"  v-model="hourWorked.oct_20"  @keyup.enter="saveHour(20,hourWorked.oct_20)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span v-if="!onHour[22]"   @dblclick="openb(22)">{{hourWorked.nov_20 == null ? 0 :hourWorked.nov_20}}</span>
        <b-form-input type="number" v-if="onHour[22]" v-model="hourWorked.nov_20"  @keyup.enter="saveHour(22,hourWorked.nov_20)"/>
      </b-td>
      <b-td style="width: 8%;" class="cursor-pointer">
        <span v-if="!onHour[24]"   @dblclick="openb(24)">{{hourWorked.dec_20 == null ? 0 :hourWorked.dec_20}}</span>
        <b-form-input type="number" v-if="onHour[24]"  v-model="hourWorked.dec_20"  @keyup.enter="saveHour(24,hourWorked.dec_20)"/>
      </b-td>
    </b-tr>
  </b-tbody>
</b-table-simple>
    </div>

  </div>
</template>

<script>
import vSelect from "vue-select";
import moment from "moment";
import EmployeesService from "@/views/management/views/employees/employees.service";
export default {
  components:{
    vSelect,
  },
  data() {
    return {
      quendo:[{hour : '00:00'},{hour : '00:30'},{hour : '01:00'},{hour : '01:30'},{hour : '02:00'},{hour : '02:30'},{hour : '03:00'},{hour : '03:30'},{hour : '04:00'},{hour : '04:30'},{hour : '05:00'},{hour : '05:30'},
        {hour : '06:00'},{hour : '06:30'},{hour : '07:00'},{hour : '07:30'},{hour : '08:00'},{hour : '08:30'},{hour : '09:00'},{hour : '09:30'},{hour : '10:00'},{hour : '10:30'},{hour : '11:00'},{hour : '11:30'},
        {hour : '12:00'},{hour : '12:30'},{hour : '13:00'},{hour : '13:30'},{hour : '14:00'},{hour : '14:30'},{hour : '15:00'},{hour : '15:30'},{hour : '16:00'},{hour : '16:30'},{hour : '17:00'},{hour : '17:30'},
        {hour : '18:00'},{hour : '18:30'},{hour : '19:00'},{hour : '19:30'},{hour : '20:00'},{hour : '20:30'},{hour : '21:00'},{hour : '21:30'},{hour : '22:00'},{hour : '22:30'},{hour : '23:00'},{hour : '23:30'}],
      min: new Date(1950, 0, 1, 0, 0, 0),
      max: new Date(2049, 11, 31, 24, 0, 0),
      value: "00:00 AM",
      year: moment().format('YYYY'),
      hourWorked: [],
      schedule:[],
      userDataHour: '',
      dates : [{date: "2017"},{date: "2018"},{date: "2019"},{date: "2020"},{date: "2021"},{date: "2022"},{date: "2023"},{date: "2024"}],
      onHour :[],
      cont: 1,
    }
  },
 async created() {
    this.addPreloader()
   await this.getDataHour()
    await  this.getSchedule()
   this.startOnHour()
   this.removePreloader()
  },
  methods :{
    saveHour(data,info){
      this.onHour[data] =  !this.onHour[data]
      this.cont++
      this.updateHoursWorked(data,info)
    },
    async getDataHour() {
      try {
        const params = {
        id: this.$route.params.id,
         year: this.year
        }
        const data = await EmployeesService.getAllHourWorked(params)
        this.hourWorked = data.data[0]
        this.startOnHour()
        this.cont++
        return this.hourWorked
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    startOnHour(){
      for(let i = 0; i <= 24; i++){
        this.onHour[i] = false
      }
    },
    async getSchedule() {
      try {
        const params = {
          id: this.$route.params.id,

        }
        const data = await EmployeesService.getSchedule(params)
        this.schedule = data.data[0]
        return this.schedule
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    async updateSched(dato,valor) {
      try {
        const params = {
          dato: dato,
          valor: valor,
          id: this.$route.params.id
        }
        const data = await EmployeesService.editSchedule(params)
        if (data.status === 200) {

          this.showToast("success", "top-right", "Update Schedule!", "Check",);

        }

      } catch (e) {
        this.showToast("danger", "top-right", "Oops!", "XIcon", "Schedule");
        return []
      }
    },

    async updateHoursWorked(dato,valor) {
      try {
        const params = {
          dato: dato,
          valor: valor,
          year: this.year,
          id: this.$route.params.id
        }
        const data = await EmployeesService.editHourWorked(params)
        if (data.status === 200) {

          this.showToast("success", "top-right", "Update Hour Worked!", "Check",);

        }

      } catch (e) {
        this.showToast("danger", "top-right", "Oops!", "XIcon", "Hour Worked!");
        return []
      }
    },
  async changehour(dato){
      switch (dato) {
        case 1: await this.updateSched(dato,this.schedule.sun_from); break;
        case 3:  await this.updateSched(dato,this.schedule.mon_from); break;
        case 5: await this.updateSched(dato,this.schedule.tue_from); break;
        case 7: await this.updateSched(dato,this.schedule.wed_from); break;
        case 9: await this.updateSched(dato,this.schedule.thu_from); break;
        case 11: await this.updateSched(dato,this.schedule.fri_from); break;
        case 13:  await this.updateSched(dato,this.schedule.sat_from); break;

        case 2: await this.updateSched(dato,this.schedule.sun_to); break;
        case 4:  await this.updateSched(dato,this.schedule.mon_to); break;
        case 6:  await this.updateSched(dato,this.schedule.tue_to); break;
        case 8:  await this.updateSched(dato,this.schedule.wed_to); break;
        case 10: await this.updateSched(dato,this.schedule.thu_to); break;
        case 12:  await this.updateSched(dato,this.schedule.fri_to); break;
        case 14:  await this.updateSched(dato,this.schedule.sat_to); break;
      }
    },
    openb(dato){
      this.onHour[dato] =  !this.onHour[dato]
      this.cont++

    },
  }
}
</script>

<style scoped>

</style>
